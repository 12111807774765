import {LitElement, html, css} from 'lit';

class TOC extends LitElement {
    constructor() {
        super();
        const content = document.querySelector('.page-content ')
        this.sections =content.querySelectorAll('h1,h2,h3,h4,h5,h6')
        this.sections.forEach(section => {
            const html = section.innerHTML;
            section.innerHTML = '<span id="' + encodeURIComponent(section.innerText) + '"></span>' + html;
        })
    }

    static styles = css`
  ol {
    list-style-type: none;
  }

  li {
    font-size: 1.6rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }

  a {
    color: #122023;
    text-decoration: none;
  }
`;

    render() {
        return html`<ol>
            ${[...this.sections].map(section=> html`<li><a href="#${encodeURIComponent(section.innerText)}">${section.innerText}</a></li>`)}
        </ol>`;
      }

}

customElements.define('toc-component', TOC)
